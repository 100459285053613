var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Component, Vue } from 'vue-property-decorator';
import { connect } from '@/overmind';
import { mixpanelTracking } from '@/services/mixpanel';
let HubSpotCallback = class HubSpotCallback extends Vue {
    constructor() {
        super(...arguments);
        this.code = '';
    }
    mounted() {
        //@ts-ignore
        this.code = decodeURI(this.$route.query.code);
        this.getTokenHubSpot();
        mixpanelTracking('integrações: Integrado com Hubspot');
    }
    async getTokenHubSpot() {
        //@ts-ignore
        await this.actions.getTokenHubSpot({
            client_id: this.hubSpotClientId,
            client_secret: this.hubSpotClientSecret,
            code: this.code,
            redirect_uri: this.rediredtUri,
        });
    }
    get rediredtUri() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? 'https://dev.speedio.com.br/crm/hubspot/callback'
            : hostname.match('test')
                ? 'https://staging.speedio.com.br/crm/hubspot/callback'
                : hostname.match('staging')
                    ? 'https://staging.speedio.com.br/crm/hubspot/callback'
                    : hostname.match('app')
                        ? 'https://app.speedio.com.br/crm/hubspot/callback'
                        : 'http://localhost:8080/crm/hubspot/callback';
    }
    get hubSpotClientId() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? '988214e3-1f87-4f65-9569-64978ffce662'
            : hostname.match('test')
                ? 'edd528f3-3875-4bea-a7ef-5b3cf3310629'
                : hostname.match('staging')
                    ? 'edd528f3-3875-4bea-a7ef-5b3cf3310629'
                    : hostname.match('app')
                        ? '0c6db7ea-9782-4f3e-bb75-8d21ae8e89ca'
                        : '8a07d258-e4de-4631-bfa0-75a9a1323a17';
    }
    get hubSpotClientSecret() {
        const hostname = window.location.hostname;
        return hostname.match('dev')
            ? 'dc822a76-7664-40c6-8b33-4fe5e46fd520'
            : hostname.match('test')
                ? 'e8e1a6c8-95b1-4f99-99ef-fe08f98a186e'
                : hostname.match('staging')
                    ? 'e8e1a6c8-95b1-4f99-99ef-fe08f98a186e'
                    : hostname.match('app')
                        ? '9d79af5a-8ba6-452c-9317-b54e9e8eac0c'
                        : '695cb52e-c2ed-4920-82cd-f37bf6e0dfd0';
    }
};
HubSpotCallback = __decorate([
    Component(connect(({ state, actions }) => ({
        state: state.users,
        actions: actions.users,
    }), {
        name: 'HubSpotCallback',
    }))
], HubSpotCallback);
export default HubSpotCallback;
